import ReactSelect, { CSSObjectWithLabel, Props as SelectProps } from 'react-select'
import CreatableSelect from 'react-select/creatable'
import { Ref, useMemo } from 'react'
import { useRecoilValue } from 'recoil'
import { omit } from 'rambda'
import { colorIsLight, darken, lighten, transparentize } from '../../util/colour'
import { DEFAULT_THEME, Theme } from '../../styles/themes/theme'
import { SiteTheme } from '../../globals/state'

export function getDropdownStyle(siteTheme: Theme, noFlex?: boolean): any {
    const adjustedBorderColor = colorIsLight(siteTheme.colors.bg0)
        ? darken(0.18, siteTheme.colors.bg0)
        : lighten(0.14, siteTheme.colors.bg3)
    return {
        option: (provided: any, state: { isSelected: any }) => ({
            ...provided,
            color: state.isSelected ? siteTheme.colors.textHeadings : siteTheme.colors.textMain,
            cursor: 'pointer',
        }),
        menu: (provided: any) => ({
            ...provided,
            margin: '0',
            boxShadow: `0 0 0 1px ${transparentize(0.4, adjustedBorderColor)}, 0 4px 11px ${transparentize(
                0.8,
                darken(0.58, siteTheme.colors.bg0)
            )}`,
        }),
        menuList: (provided: any) => ({
            ...provided,
            padding: '0',
        }),

        dropdownIndicator: (provided: any) => ({
            ...provided,
            color: siteTheme.colors.textMain,
        }),
        valueContainer: (provided: any, state: any) => {
            return {
                ...provided,
                '&:focus-within':
                    state.selectProps.isSearchable && state.children[0] !== null
                        ? {
                              opacity: 0.3,
                          }
                        : {},
                width: 0,
            }
        },
        singleValue: (provided: any) => ({
            ...provided,
            overflow: 'visible',
        }),
        multiValue: (provided: any) => ({
            ...provided,
            background: siteTheme.colors.bg2,
        }),
        clearIndicator: (provided: any) => ({
            ...provided,
            color: transparentize(0.7, siteTheme.colors.textMain),
        }),
        control: (provided: any) => ({
            ...provided,
            border: '0px !important',
            boxShadow: '0px !important',
            outline: `1px solid ${siteTheme.colors.bg3} !important`,
            cursor: 'pointer',
            height: '100%',
            borderRadius: '3px',
            overflow: 'hidden',
        }),
        placeholder: (provided: any) => ({
            ...provided,
            color: transparentize(0.8, siteTheme.colors.textMain),
        }),
        container: (provided: any) => ({
            ...provided,
            flex: noFlex ? '0 0 auto' : '1 1 auto',
            overflow: 'visible',
            boxShadow: 'none',
            '::after': {
                content: '""',
                position: 'absolute',
                left: '0',
                top: '0',
                width: '100%',
                height: '100%',
                boxShadow: `0 0 1px 0 ${adjustedBorderColor}`,
                pointerEvents: 'none',
            },
        }),
    }
}
export function getDropdownTheme(siteTheme: Theme, alternate: boolean = false): any {
    const primary = alternate ? 'bg0' : 'bg1'
    const secondary = alternate ? 'bg3' : 'bg2'
    return (theme: any) => ({
        ...theme,
        colors: {
            ...theme.colors,
            // Focused outline. Selected item background
            primary: siteTheme.colors[primary],
            primary95: transparentize(0.33, siteTheme.colors[primary]),
            primary90: transparentize(0.33, siteTheme.colors[primary]),
            primary75: transparentize(0.33, siteTheme.colors[primary]),
            primary50: transparentize(0.4, siteTheme.colors[primary]),
            primary25: transparentize(0.4, siteTheme.colors[primary]),
            neutral0: siteTheme.colors[secondary],
            neutral20: siteTheme.colors[primary],
            // Control border focused hover
            neutral30: siteTheme.colors[secondary],
            neutral40: transparentize(0.4, siteTheme.colors.textMain),
            neutral50: transparentize(0.4, siteTheme.colors[primary]),
            neutral60: transparentize(0.4, siteTheme.colors[primary]),
            neutral70: transparentize(0.4, siteTheme.colors[primary]),
            neutral80: siteTheme.colors.textMain,
            neutral90: transparentize(0.4, siteTheme.colors[primary]),
            neutral10: transparentize(0.7, siteTheme.colors[secondary]),
            neutral5: transparentize(0.33, siteTheme.colors[secondary]),
            // multi button hover color
            dangerLight: transparentize(0.4, siteTheme.colors.bg2),
            // Multi X hover color
            danger: siteTheme.colors.warning,
        },
        borderRadius: 0,
    })
}

const onFocus = (e: { focused: any; isDisabled: any }) => {
    const msg = `You are currently focused on option ${
        e.focused.description ?? e.focused.rawLabel ?? e.focused.label
    }${e.isDisabled ? ', disabled' : ''}`
    return msg
}
interface Props<T, Y extends boolean = false> extends SelectProps<T, Y> {
    custom?: boolean
    isValidNewOption?: (inputValue: string) => boolean
    formatCreateLabel?: (inputValue: string) => string
}

// eslint-disable-next-line react/function-component-definition
export const Select = <Option = unknown, isMulti extends boolean = false>(
    props: Props<Option, isMulti> & {
        alternate?: boolean
        noFlex?: boolean
        height?: number
        parentRef?: Ref<any>
    }
): JSX.Element => {
    const value = props?.value as any

    if (props?.value && typeof value.label === 'object') {
        const element = Object.create((props.value as any).label)
        element.toString = () =>
            (props.value as any).description ?? (props.value as any).value ?? 'unknown option'
        ;(props.value as any).label = element
    }

    const siteTheme = useRecoilValue(SiteTheme)

    const _props = useMemo(() => {
        const styles = props.styles ?? getDropdownStyle(siteTheme ?? DEFAULT_THEME, props.noFlex)
        const theme = props.theme ?? getDropdownTheme(siteTheme ?? DEFAULT_THEME, props.alternate)
        if (props.height) {
            const control = styles.control
            styles.control = (base: CSSObjectWithLabel) => ({
                ...base,
                ...control(),
                minHeight: props.height,
            })
        }
        return {
            ...omit(['styles', 'themes', 'noFlex', 'alternate'], props),
            styles,
            theme,
        }
    }, [props, siteTheme])

    return (
        <>
            {useMemo(
                () =>
                    _props.custom ? (
                        <CreatableSelect
                            ref={props.parentRef}
                            formatGroupLabel={(data) =>
                                data.label?.split('\n')?.map(
                                    (x, i) =>
                                        (
                                            <div
                                                style={{
                                                    textTransform: i === 0 ? 'uppercase' : 'none',
                                                }}
                                            >
                                                {x}
                                            </div>
                                        ) ?? ''
                                )
                            }
                            {..._props}
                            blurInputOnSelect={true}
                            className={`${_props.className ?? ''} select`}
                            ariaLiveMessages={{ onFocus }}
                            createOptionPosition="first"
                        />
                    ) : (
                        <ReactSelect
                            ref={props.parentRef}
                            formatGroupLabel={(data) =>
                                data.label?.split('\n')?.map(
                                    (x, i) =>
                                        (
                                            <div
                                                style={{
                                                    textTransform: i === 0 ? 'uppercase' : 'none',
                                                }}
                                            >
                                                {x}
                                            </div>
                                        ) ?? ''
                                )
                            }
                            {..._props}
                            blurInputOnSelect={true}
                            className={`${_props.className ?? ''} select`}
                            ariaLiveMessages={{ onFocus }}
                        />
                    ),
                [_props]
            )}
        </>
    )
}
